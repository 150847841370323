import { t } from "i18next";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";

import { ReactComponent as LinkIcon } from "assets/images/icons/link.svg";
import { ReactComponent as LockupIcon } from "assets/images/icons/lockup.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { contractId } from "services/config";
import { FOUR, LOCKUP_URL, MILLION, TWO, ZERO, ZERO_STRING } from "shared/constants";
import { getTimeoutValue } from "shared/helpers";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import useGetRewardData from "shared/hooks/useGetRewardData";
import theme from "shared/theme";
import { BigNumber, formatUnitsWithSpaces } from "shared/utils/calculations";
import { claim } from "store/actions/claim";
import { unlockFunds } from "store/actions/unlockFunds";
import { updateState } from "store/actions/updateState";
import { selectGlobalLoading } from "store/selectors/selectGlobalLoading";
import { selectPrestakingData } from "store/selectors/selectPrestakingData";
import { EModals, showModal } from "store/slices/modals";
import { selectTokens } from "store/slices/tokens";
import { selectAccountId } from "store/slices/user";

import { Preloading } from "./components/Preloader";
import styles from "./styles";
import { ButtonPrimary } from "../Buttons/ButtonPrimary";
import { EButtonColors, EButtonSizes } from "../Buttons/interfaces";
import CountdownTimer from "../CountdownTimer";
import { MenuAdditional } from "../MenuAdditional";
import { Translate } from "../Translate";

export const BalancesTimerBlock: React.FC = () => {
  const dispatch = useAppDispatch();
  const { RPCProvider, requestSignTransactions } = useWalletSelector();
  const { startDate, endDate, whitelistedTokens, isLockEnded, isLockStarted, userStakingBalances } =
    useAppSelector(selectPrestakingData);
  const { userEstimatedReward, rewardByTokens } = useGetRewardData();

  const loading = useAppSelector(selectGlobalLoading);
  const tokens = useAppSelector(selectTokens);
  const accountId = useAppSelector(selectAccountId);

  const whiteListedTokenIds = Object.keys(whitelistedTokens);

  const [timer, setTimer] = useState<number>(getTimeoutValue(startDate, endDate));

  const claimAvailable = Object.values(userStakingBalances).some((balance) => BigNumber(balance).gt(ZERO));

  const goToLockup = () => window.open(LOCKUP_URL, "_blank", "noreferrer");

  const claimHandler = () => {
    dispatch(
      claim({
        provider: RPCProvider,
        requestSignTransactions,
      })
    );
  };

  const unlockFundsHandler = (tokenId: string) => {
    dispatch(
      unlockFunds({
        tokenId,
        provider: RPCProvider,
        requestSignTransactions,
      })
    );
  };

  useEffect(() => {
    if (!timer) return;
    const newTimer = getTimeoutValue(startDate / MILLION, endDate / MILLION);
    if (newTimer === timer) return;
    const timeoutId = setTimeout(() => {
      dispatch(updateState({ provider: RPCProvider, contractId }));
      setTimer(getTimeoutValue(startDate, endDate));
    }, timer);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [timer]);

  if (loading) return <Preloading />;

  return (
    <>
      <styles.TimerWrapper>
        {!!timer && (
          <>
            <styles.TimerTitle>
              {!isLockStarted ? t("assetsList.timer.title.beforeStart") : t("assetsList.timer.title.beforeEnd")}
            </styles.TimerTitle>
            <CountdownTimer timer={timer} />
          </>
        )}
      </styles.TimerWrapper>
      {!isEmpty(tokens) && (
        <styles.BalancesBlockWrapper>
          {whiteListedTokenIds.map((tokenId) => (
            <styles.AssetItem key={tokenId}>
              <styles.TokenDataWrapper>
                <styles.IconToken src={tokens[tokenId].metadata.icon} />
                <styles.TokenData>
                  <styles.TokenName>{tokens[tokenId].metadata.symbol}</styles.TokenName>
                  <styles.TokenDescription>{tokens[tokenId].metadata.name}</styles.TokenDescription>
                </styles.TokenData>
              </styles.TokenDataWrapper>
              <styles.StakeDataWrapper>
                <styles.StakeData>
                  <styles.StakeTitle>{t("assetsList.stakeData.yourStake")}</styles.StakeTitle>
                  <styles.StakeValue>
                    {(!isEmpty(userStakingBalances) &&
                      userStakingBalances[tokenId] &&
                      formatUnitsWithSpaces(userStakingBalances[tokenId], tokens[tokenId].metadata.decimals, FOUR)) ||
                      ZERO_STRING}
                  </styles.StakeValue>
                </styles.StakeData>
                {!isLockStarted && !isLockEnded && (
                  <styles.StakeMenuWrapper>
                    <ButtonPrimary
                      name={t("buttons.stakeNow")}
                      handler={() =>
                        dispatch(showModal({ modal: EModals.STAKING_MODAL, props: { whiteListTokenId: tokenId } }))
                      }
                      disabled={!accountId || isLockStarted}
                    />
                    <MenuAdditional>
                      <ButtonPrimary
                        name={t("actions.claimWithoutReward")}
                        handler={() => unlockFundsHandler(tokenId)}
                        disabled={
                          !accountId ||
                          !userStakingBalances[tokenId] ||
                          !BigNumber(userStakingBalances[tokenId]).gt(ZERO)
                        }
                      />
                    </MenuAdditional>
                  </styles.StakeMenuWrapper>
                )}
              </styles.StakeDataWrapper>
            </styles.AssetItem>
          ))}
          {isLockEnded && claimAvailable && (
            <styles.ClaimAllWrapper>
              <ButtonPrimary name={t("actions.claimAll")} handler={claimHandler} fullWidth />
            </styles.ClaimAllWrapper>
          )}
          <styles.RewardItem>
            <styles.TokenDataWrapper>
              <LockupIcon />
              <styles.TokenData>
                <styles.RewardName>
                  <Translate value="assetsList.reward.title" />
                </styles.RewardName>
                <styles.RewardDescription>
                  <Translate value="assetsList.reward.description" />
                </styles.RewardDescription>
                <ButtonPrimary
                  name={t("actions.goToLockup")}
                  handler={goToLockup}
                  color={EButtonColors.GREY}
                  size={EButtonSizes.XS}
                  iconRight={<LinkIcon />}
                  textColor={theme.colors.blue}
                />
              </styles.TokenData>
            </styles.TokenDataWrapper>
            <styles.StakeDataWrapper>
              <styles.StakeData>
                <styles.StakeTitle>
                  <Translate value="assetsList.reward.fiatAmount.title" />
                </styles.StakeTitle>
                <styles.StakeValue>
                  <Trans
                    i18nKey="assetsList.reward.fiatAmount.value"
                    values={{ value: userEstimatedReward.toFixed(TWO) }}
                  />
                </styles.StakeValue>
              </styles.StakeData>
              <styles.RewardList>
                {whiteListedTokenIds.map((tokenId) => (
                  <styles.TokenDataWrapper key={tokenId}>
                    {!isEmpty(tokens) && (
                      <styles.RewardListItem>
                        <styles.IconToken src={tokens[tokenId].metadata.icon} small />
                        <styles.TokenRewardData>
                          <Trans
                            i18nKey="assetsList.reward.tokenAmount"
                            values={{ amount: rewardByTokens[tokenId], name: tokens[tokenId].metadata.symbol }}
                          />
                        </styles.TokenRewardData>
                      </styles.RewardListItem>
                    )}
                  </styles.TokenDataWrapper>
                ))}
              </styles.RewardList>
            </styles.StakeDataWrapper>
          </styles.RewardItem>
        </styles.BalancesBlockWrapper>
      )}
    </>
  );
};
