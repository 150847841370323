import { t } from "i18next";

import { ReactComponent as NearIcon } from "assets/images/icons/near-icon.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { ButtonsGroup } from "shared/components/Buttons/ButtonsGroup";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { ModalHeader } from "shared/components/modals/components/ModalHeader";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { Translate } from "shared/components/Translate";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { IModalProps } from "shared/interfaces";
import { cropString } from "shared/utils/cropString";
import { selectAccountId } from "store/slices/user";

import styles from "./styles";

export const AccountModal: React.FC<IModalProps> = ({ closeModal }: IModalProps) => {
  const { signOut } = useWalletSelector();
  const accountId = useAppSelector(selectAccountId);

  return (
    <ModalWrapper closeModal={closeModal}>
      <ModalHeader />
      <styles.Title>
        <Translate value="modals.account.title" />
      </styles.Title>
      <styles.AccountId>
        <NearIcon />
        {cropString(accountId)}
      </styles.AccountId>
      <styles.ButtonsWrapper>
        <ButtonsGroup
          cancelButtonHandler={closeModal}
          rightButton={{
            color: EButtonColors.RED,
            name: t("actions.disconnect"),
            handler: () => {
              closeModal();
              signOut();
            },
          }}
        />
      </styles.ButtonsWrapper>
    </ModalWrapper>
  );
};
