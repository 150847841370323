import { motion } from "framer-motion";
import styled from "styled-components";

const BackgroundLayout = styled(motion.div)`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 199;
  height: 100%;
  display: flex;
  overflow: auto;
  position: fixed;
  justify-content: center;
  backdrop-filter: blur(8px);
`;

const Modal = styled(motion.div)<{ $widthInRem: string; $clearDefaultStyles?: boolean }>`
  z-index: 200;
  max-width: 90%;
  overflow: auto;
  padding: 1.5rem;
  position: relative;
  align-self: center;
  height: fit-content;
  width: ${({ $widthInRem }) => `${$widthInRem}rem`};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 3rem 100%, 0 calc(100% - 3rem));
  filter: url(#huge);
  ${({ theme }) => theme.mediaWidth("upToSmall", `padding: 1.5rem 1rem;`)}

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    background-color: ${({ theme }) => theme.colors.white};
    clip-path: polygon(0 0, 100% 0, 100% 100%, 3rem 100%, 0 calc(100% - 3rem));
  }
`;

const ModalHolder = styled.div`
  overflow: auto;
  max-height: 80vh;
`;

export default {
  BackgroundLayout,
  Modal,
  ModalHolder,
};
