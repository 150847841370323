import { createAsyncThunk } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";

import { getUserWalletTokens } from "services/api/userTokens";
import PreStakingContract from "services/contracts/PreStakingContract";
import { IRPCProviderService } from "services/RPCProviderService";
import { RootState } from "store";
import { setContractInfo, setPreStakingLoading, setUserData } from "store/slices/prestakingData";

import { loadPrices } from "./loadPrices";
import { loadRewardBalances } from "./loadRewardBalances";
import { loadTokens } from "./loadTokens";

export const loadPreStakingData = createAsyncThunk<
  void,
  {
    provider: IRPCProviderService;
    contractId: string;
  },
  { state: RootState }
>("preStaking/loadPreStakingData", async ({ provider, contractId }, { dispatch, getState }) => {
  try {
    const state = getState();
    const accountId = state.user.id;
    const preStaking = new PreStakingContract({ provider, contractId });

    const [user, contractInfo, userTokensId] = await Promise.all([
      await preStaking.getUser({ userId: accountId }),
      await preStaking.getContractInfo(),
      await getUserWalletTokens(accountId),
    ]);

    if (!contractInfo) return;
    const whiteListedTokenAddresses = Object.keys(contractInfo.whitelistedTokens);
    const tokenAddresses = uniq(whiteListedTokenAddresses.concat(userTokensId));

    user && dispatch(setUserData(user));
    contractInfo && dispatch(setContractInfo(contractInfo));
    dispatch(loadTokens({ tokenAddresses, provider }));
    dispatch(loadRewardBalances({ tokenAddresses: whiteListedTokenAddresses, provider }));
    dispatch(loadPrices({ tokenAddresses }));
  } catch (e) {
    console.error(`Error: while loading pre-staking data from contract \n ${e}`);
  } finally {
    dispatch(setPreStakingLoading(false));
  }
});
