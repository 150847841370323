import { AccountView } from "near-api-js/lib/providers/provider";

import { FTViewMethod } from "services/contracts/FungibleToken/interfaces";
import { ContractInfoOutput, PreStakingViewMethods } from "services/contracts/PreStakingContract/interfaces";
import { ITokenMetadata } from "shared/interfaces";

export interface ViewArgs {
  from_index?: number;
  limit?: number;
  account_id?: string;
  owner_id?: string;
  user_id?: string;
}

export interface IRPCProviderService {
  viewFunction: <T extends keyof ViewFunction>(
    method: T,
    accountId: string,
    args?: ViewArgs
  ) => Promise<ViewFunction[T] | undefined>;
  viewAccount: (accountId: string) => Promise<AccountView | undefined>;
}

export enum RPCProviderMethods {
  CALL_FUNCTION = "call_function",
  VIEW_ACCOUNT = "view_account",
}

export type ViewFunction = {
  //PreStaking Contract
  [PreStakingViewMethods.getJoinFee]: Promise<string | undefined>;
  [PreStakingViewMethods.getUser]: Promise<{ [key: string]: string } | undefined>;
  [PreStakingViewMethods.getContractInfo]: Promise<ContractInfoOutput | undefined>;
  //FT
  [FTViewMethod.ftMetadata]: Promise<ITokenMetadata | null>;
  [FTViewMethod.ftBalanceOf]: Promise<string | undefined>;
  [FTViewMethod.storageBalanceBounds]: Promise<{ min: string; max: string }>;
  [FTViewMethod.storageBalanceOf]: Promise<{ total: string; available: string }>;
};
