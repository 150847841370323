import { t } from "i18next";

import { ReactComponent as Jumbo } from "assets/images/partners/jumbo.svg";
import { ReactComponent as Lockup } from "assets/images/partners/lockup.svg";
import { ReactComponent as Near } from "assets/images/partners/near.svg";
import { H2 } from "shared/components/Typography";

import styles from "./styles";

export const PartnersBlock = () => {
  return (
    <styles.PartnersBlockWrapper>
      <H2 text={t("partners.title")} isCentered />
      <styles.LogoWrapper>
        <Jumbo />
        <Near />
        <Lockup />
      </styles.LogoWrapper>
    </styles.PartnersBlockWrapper>
  );
};
