import { IConfig } from "../config";

export default {
  networkId: "mainnet",
  nodeUrl: "https://rpc.mainnet.near.org",
  walletUrl: "https://wallet.near.org",
  helperUrl: "https://api.kitwallet.app",
  explorerUrl: "https://explorer.mainnet.near.org",
  contractId: "", //TODO: add contractId
  rewardContractId: "", //TODO: add rewardContractId
  wNearAddress: "wrap.near",
  usn: "usn",
  myNearWalletUrl: "https://app.mynearwallet.com/",
  refFinanceIndexerUrl: "https://indexer.ref.finance",
  jumboExchangeIndexerUrl: "https://price-service.jumbo.exchange",
  walletDomain: ".near",
  senderWalletUrl: "https://sender.org/?origin=ref",
} as IConfig;
