import { FinalExecutionOutcome } from "@near-wallet-selector/core";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";
import isEmpty from "lodash/isEmpty";

import { contractId } from "services/config";
import PreStakingContract from "services/contracts/PreStakingContract";
import RPCProviderService from "services/RPCProviderService";
import ToastService from "shared/components/Toast";
import { getToken } from "shared/helpers";
import { ITransaction } from "shared/interfaces";
import { RootState } from "store";

import { updateState } from "./updateState";

export const stake = createAsyncThunk<
  void,
  {
    amount: string;
    tokenId: string;
    provider: RPCProviderService;
    requestSignTransactions: (transaction: ITransaction[]) => Promise<void | FinalExecutionOutcome[]>;
  },
  { state: RootState }
>("preStaking/stake", async ({ amount, tokenId, provider, requestSignTransactions }, { getState, dispatch }) => {
  try {
    const {
      preStaking,
      tokens: { tokens },
    } = getState();

    const token = getToken(tokenId, tokens);
    if (!token) return;

    const transactions: ITransaction[] = [];

    if (isEmpty(preStaking.userData)) {
      const preStaking = new PreStakingContract({ provider, contractId });
      const joinTransaction = await preStaking.createJoinTransaction();
      joinTransaction && transactions.push(joinTransaction);
    }

    const createFtTransferArgs = () => ({
      receiver_id: contractId,
      amount,
      msg: JSON.stringify({
        token_id: tokenId,
        amount,
      }),
    });

    const ftTransferArgs = createFtTransferArgs();
    const ftTransferTransactions = await token.createFtTransferCallTransaction(contractId, ftTransferArgs);

    if (!ftTransferTransactions) return;
    transactions.push(...ftTransferTransactions);

    const result = await requestSignTransactions(transactions);

    if (result) dispatch(updateState({ provider: provider, contractId }));
  } catch (error) {
    ToastService.error({ text: t("toast.error.stake") });
    console.error("Error during token stake: ", error);
  }
});
