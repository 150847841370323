import FungibleTokenContract from "services/contracts/FungibleToken";
import { getTokenColor } from "shared/helpers";
import { formatUnits } from "shared/utils/calculations";

export const setDoughnutData = (
  whiteListedTokens: { [key: string]: string },
  tokens: { [key: string]: FungibleTokenContract }
) => {
  const tokenIds = Object.keys(whiteListedTokens);
  const data = tokenIds.map((tokenId) => formatUnits(whiteListedTokens[tokenId], tokens[tokenId].metadata.decimals));
  const colors = tokenIds.map((tokenId) => getTokenColor(tokenId));

  return { data, colors };
};
