import styled from "styled-components";

import { flexRowNoWrap, hoverTransition } from "shared/theme/styles";

import { getButtonHeight, getButtonStatusColors } from "./helpers";
import { EButtonColors, EButtonSizes } from "./interfaces";

const ButtonPrimaryWrapper = styled.button<{
  fullWidth: boolean;
  color: EButtonColors;
  size: EButtonSizes;
  textColor?: string;
}>`
  ${flexRowNoWrap}
  gap: 0.25rem;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 700;
  font-style: normal;
  align-items: center;
  border-radius: 0.5rem;
  line-height: 1.188rem;
  justify-content: center;
  height: ${({ size }) => getButtonHeight(size)};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  background: ${({ color }) => getButtonStatusColors(color).default};
  color: ${({ theme, textColor }) => textColor || theme.colors.white};
  font-size: ${({ size }) => (size === EButtonSizes.XS || size === EButtonSizes.S ? "0.6625rem" : "1rem")};
  padding: ${({ size }) =>
    size === EButtonSizes.XS || size === EButtonSizes.S ? "0.5rem 0.40625rem" : "0.75rem 0.625rem"};
  transition: ${hoverTransition};

  svg {
    path {
      fill: ${({ theme, textColor }) => textColor || theme.colors.white};
    }
  }

  &:hover {
    background: ${({ color }) => getButtonStatusColors(color).hover};
  }

  &:active {
    background: ${({ color }) => getButtonStatusColors(color).active};
  }

  &:disabled {
    background: ${({ color }) => getButtonStatusColors(color).disabled};
    cursor: not-allowed;
  }
`;

const ButtonIconWrapper = styled.span`
  line-height: 0;
  cursor: pointer;
  display: inline-block;

  svg {
    path {
      transition: ${hoverTransition};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.iconHovered};
      }
    }
  }
`;

const ButtonEmptyWrapper = styled(ButtonPrimaryWrapper)`
  padding: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.greyDark};

  &:hover {
    background: transparent;
    color: ${({ theme }) => theme.colors.iconHovered};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.iconHovered};
      }
    }
  }

  &:active {
    background: transparent;
    color: ${({ theme }) => theme.colors.blue};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.blue};
      }
    }
  }
`;

const ButtonSimWrapper = styled(ButtonPrimaryWrapper)`
  height: 2.175rem;
  line-height: 140%;
  background: unset;
  position: relative;
  filter: url(#small);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 0.35rem;
    background: ${({ color }) => getButtonStatusColors(color).default};
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0.6rem 100%, 0 calc(100% - 0.6rem));
    transition: ${hoverTransition};
  }

  &:hover {
    background: unset;

    &:before {
      background: ${({ color }) => getButtonStatusColors(color).hover};
    }
  }

  &:active {
    background: unset;

    &:before {
      background: ${({ color }) => getButtonStatusColors(color).active};
    }
  }
`;

export default {
  ButtonPrimaryWrapper,
  ButtonIconWrapper,
  ButtonEmptyWrapper,
  ButtonSimWrapper,
};
