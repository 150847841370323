import { t } from "i18next";
import isEmpty from "lodash/isEmpty";
import { Trans } from "react-i18next";

import { ChartDoughnut } from "shared/components/Chart/Doughnut";
import { CHART_DOUGHNUT_CUTOUT_DEFAULT, CHART_DOUGHNUT_CUTOUT_MOBILE, EDimensions, FOUR, TWO } from "shared/constants";
import { getTokenColor } from "shared/helpers";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import useGetRewardData from "shared/hooks/useGetRewardData";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import theme from "shared/theme";
import { BigNumber, calculateAPY, formatUnitsWithSpaces } from "shared/utils/calculations";
import { getDifferenceBetweenNanosecInDays } from "shared/utils/getDifferenceBetweenNanosecInDays";
import { getUserFriendlyAmount } from "shared/utils/getUserFriendlyAmount";
import { selectData } from "store/selectors/selectData";
import { selectGlobalLoading } from "store/selectors/selectGlobalLoading";
import { selectPrestakingData } from "store/selectors/selectPrestakingData";
import { selectRewardAccountBalances } from "store/slices/rewardAccountData";

import { Preloading } from "./components/Preloader";
import styles from "./styles";
import Tooltip from "../Tooltip";
import { Translate } from "../Translate";

export const StakingPoolInformation: React.FC = () => {
  const dimensions = useWindowDimensions();
  const { tokens } = useAppSelector(selectData);
  const loading = useAppSelector(selectGlobalLoading);
  const rewardBalances = useAppSelector(selectRewardAccountBalances);
  const { totalStakedFiat, rewardAmountFiat } = useGetRewardData();
  const { whitelistedTokens, usersCount, startDate, endDate } = useAppSelector(selectPrestakingData);

  const duration = getDifferenceBetweenNanosecInDays(startDate, endDate);
  const apy = calculateAPY(duration, totalStakedFiat, rewardAmountFiat);
  const whitelistedTokenIds = Object.keys(whitelistedTokens);
  const isNotZeroAmount = whitelistedTokens
    ? Object.values(whitelistedTokens).some((amount) => !BigNumber(amount).eq(0))
    : false;
  const isMobile = dimensions === EDimensions.SMALL;

  if (isEmpty(rewardBalances) || isEmpty(tokens) || !whitelistedTokens || isEmpty(whitelistedTokens) || loading)
    return <Preloading />;

  return (
    <styles.StakingPoolInformationWrapper>
      <styles.TotalReward>
        <styles.Title>
          <Translate value="stakingPoolInformation.totalReward.title" />
        </styles.Title>
        <styles.Value>
          <Trans i18nKey="stakingPoolInformation.totalReward.value" values={{ value: rewardAmountFiat.toFixed(TWO) }} />
        </styles.Value>
        <styles.RewardTokensList>
          {whitelistedTokenIds.map((tokenId: string) => (
            <styles.RewardTokenItem key={tokenId}>
              <styles.Icon src={tokens[tokenId].metadata.icon} />
              <styles.TokenAmount>
                <Trans
                  i18nKey="stakingPoolInformation.tokenListItem"
                  values={{
                    amount: formatUnitsWithSpaces(
                      rewardBalances[tokenId].balance,
                      tokens[tokenId].metadata.decimals,
                      FOUR
                    ),
                    symbol: tokens[tokenId].metadata.symbol,
                  }}
                />
              </styles.TokenAmount>
            </styles.RewardTokenItem>
          ))}
        </styles.RewardTokensList>
      </styles.TotalReward>
      <styles.APR>
        <styles.Title>
          <Translate value="stakingPoolInformation.apy.title" />
          <Tooltip title={t("tooltip.apy")} />
        </styles.Title>
        <styles.Value color={theme.colors.green}>
          <Trans i18nKey="stakingPoolInformation.apy.value" values={{ value: apy }} />
        </styles.Value>
      </styles.APR>
      <styles.Duration>
        <styles.Title>
          <Translate value="stakingPoolInformation.duration.title" />
        </styles.Title>
        <styles.Value>
          <Trans i18nKey="stakingPoolInformation.duration.value" values={{ value: duration }} />
        </styles.Value>
      </styles.Duration>
      <styles.TotalStakers>
        <styles.Title>
          <Translate value="stakingPoolInformation.totalStakers.title" />
        </styles.Title>
        <styles.Value>
          <Trans i18nKey="stakingPoolInformation.totalStakers.value" values={{ value: usersCount }} />
        </styles.Value>
      </styles.TotalStakers>
      <styles.TotalStaked>
        <styles.TotalStakedDataWrapper>
          <div>
            <styles.Title>
              <Translate value="stakingPoolInformation.totalStaked.title" />
            </styles.Title>
            <styles.Value>
              <Trans
                i18nKey="stakingPoolInformation.totalStaked.value"
                values={{ value: getUserFriendlyAmount(totalStakedFiat) }}
              />
            </styles.Value>
          </div>
          <div>
            {whitelistedTokenIds.map((tokenId: string) => (
              <styles.StakeTokenItem key={`stake-${tokenId}`}>
                <styles.Circle color={getTokenColor(tokenId)} />
                <styles.StakeTokenItemValue>
                  <Trans
                    i18nKey="stakingPoolInformation.tokenListItem"
                    values={{
                      amount: formatUnitsWithSpaces(
                        whitelistedTokens[tokenId],
                        tokens[tokenId].metadata.decimals,
                        FOUR
                      ),
                      symbol: tokens[tokenId].metadata.symbol,
                    }}
                  />
                </styles.StakeTokenItemValue>
              </styles.StakeTokenItem>
            ))}
          </div>
        </styles.TotalStakedDataWrapper>
        <styles.ChartWrapper>
          {whitelistedTokens && isNotZeroAmount ? (
            <ChartDoughnut
              whiteListedTokens={whitelistedTokens}
              cutout={isMobile ? CHART_DOUGHNUT_CUTOUT_MOBILE : CHART_DOUGHNUT_CUTOUT_DEFAULT}
            />
          ) : (
            <ChartDoughnut cutout={isMobile ? CHART_DOUGHNUT_CUTOUT_MOBILE : CHART_DOUGHNUT_CUTOUT_DEFAULT} />
          )}
        </styles.ChartWrapper>
      </styles.TotalStaked>
    </styles.StakingPoolInformationWrapper>
  );
};
