import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { CHART_DOUGHNUT_CUTOUT_DEFAULT, ONE_HUNDRED } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import theme from "shared/theme";
import { selectTokens } from "store/slices/tokens";

import { IDoughnutProps } from "./interface";
import { setDoughnutData } from "../utils";

ChartJS.register(ArcElement, Tooltip, Legend);

export const ChartDoughnut: React.FC<IDoughnutProps> = ({
  cutout = CHART_DOUGHNUT_CUTOUT_DEFAULT,
  data = [ONE_HUNDRED],
  backgroundColor = [theme.colors.greyDoughnut],
  whiteListedTokens,
}: IDoughnutProps) => {
  const tokens = useAppSelector(selectTokens);
  const doughnutData = whiteListedTokens ? setDoughnutData(whiteListedTokens, tokens) : null;

  const dataOptions = {
    datasets: [
      {
        data: doughnutData ? doughnutData.data : data,
        backgroundColor: doughnutData ? doughnutData.colors : backgroundColor,
        borderWidth: 0,
      },
    ],
  };

  return <Doughnut data={dataOptions} options={{ cutout }} />;
};
